import { GetListServiceConfigTypes } from './actionTypes';
import {
    IGetListServiceConfigSuccess,
    IGetListServiceConfigError,
    IGetListServiceConfigFetching,
    IGetListServiceConfig,
    IGetListServiceConfigFilter,
    IGetListServiceConfigFiterDebounce
} from "./interfaces";


export const getListServiceConfig = (): IGetListServiceConfig => ({
    type: GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG,
})

export const getListServiceConfigFetching = (isFetching: boolean): IGetListServiceConfigFetching => ({
    type: GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG_FETCHING,
    isFetching
})

export const getListServiceConfigSuccess = (data: any): IGetListServiceConfigSuccess => ({
    type: GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG_SUCCESS,
    data
})

export const getListServiceConfigError = (errorMsg: any): IGetListServiceConfigError => ({
    type: GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG_FAILD,
    error: errorMsg
})

export const getListServiceConfigFilter = (payload: any): IGetListServiceConfigFilter => ({
    type: GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG_FILTER,
    payload
})

export const getListServiceConfigFilterDebounce = (payload: any): IGetListServiceConfigFiterDebounce => ({
    type: GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG_FILTER_DEBOUNCE,
    payload
})
