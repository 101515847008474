interface IDataListServiceConfig {
    data: any[],
    error: any;
    success: boolean;
}

export interface IListServiceConfigState {
    data: IDataListServiceConfig;
    isFetching: boolean,
}

export const initialListServiceConfigState: IListServiceConfigState = {
    data: {
        data: [],
        error: null,
        success: false
    },
    isFetching: false,
}

