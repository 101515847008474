import Request from '../../config';
import * as Sentry from '@sentry/react';

const requestApi = new Request({
    url: process.env.REACT_APP_BASE_URL_TRANSFER
});

const handleGetAllServiceConfig = async (): Promise<any> => {
    try {
        return await requestApi.get(`/ServiceConfig/GetAll`);
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
};

const handleUpdateServiceConfig = async (data: any): Promise<any> => {
    try {
        return await requestApi.post(`/ServiceConfig/Edit`, data);
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
};


export {
    handleGetAllServiceConfig,
    handleUpdateServiceConfig,
};
