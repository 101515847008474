interface IDataEditServiceConfig {
    data: any[],
    error: any;
}

export interface IEditServiceConfigState {
    data: IDataEditServiceConfig;
    isFetching: boolean,
    success: any
}

export const initialEditServiceConfigState: IEditServiceConfigState = {
    data: {
        data: [],
        error: '',
    },
    isFetching: false,
    success: false
}

