import { DataEditUserByAdmin } from 'src/apis/service/user';
import { EditUserByAdminType } from './actionTypes';
import {
  IEditUserByAdmin,
  IEditUserByAdminFaild,
  IEditUserByAdminFetching,
  IEditUserByAdminSuccess,
} from './interfaces';

export const editUserByAdminAtFetching = (
  isFetching: boolean
): IEditUserByAdminFetching => ({
  type: EditUserByAdminType.EDIT_USER_BY_ADMIN_FETCHING,
  isFetching,
});

export const editUserByAdmin = (
  payload: DataEditUserByAdmin
): IEditUserByAdmin => ({
  type: EditUserByAdminType.EDIT_USER_BY_ADMIN,
  payload,
});

export const editUserByAdminSuccess = (data: any): IEditUserByAdminSuccess => ({
  type: EditUserByAdminType.EDIT_USER_BY_ADMIN_SUCCESS,
  data,
});

export const editUserByAdminFaild = (error: any): IEditUserByAdminFaild => ({
  type: EditUserByAdminType.EDIT_USER_BY_ADMIN_FAILD,
  error,
});
