import { AnyAction, Reducer } from 'redux';
import { IListServiceConfigState, initialListServiceConfigState } from './state';
import { GetListServiceConfigTypes } from '../../actions/getListServiceConfig';

export const GetListServiceConfigReducer: Reducer = (
  state: IListServiceConfigState = initialListServiceConfigState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG_FILTER: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
