import { AnyAction, Reducer } from 'redux';
import { IEditServiceConfigState, initialEditServiceConfigState } from './state';
import { EditServiceConfigTypes } from '../../actions/editServiceConfig';

export const EditServiceConfigReducer: Reducer = (
  state: IEditServiceConfigState = initialEditServiceConfigState,
  action: AnyAction
) => {
  switch (action.type) {
    case EditServiceConfigTypes.EDIT_SERVICE_CONFIG: {
      return {
        ...state,
        isFetching: true,
        success: false
      };
    }
    case EditServiceConfigTypes.EDIT_SERVICE_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        success: true
      };
    }
    case EditServiceConfigTypes.EDIT_SERVICE_CONFIG_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
        success: false
      };
    }
    default: {
      return state;
    }
  }
};
