import { put, takeEvery } from 'redux-saga/effects';
import { EditServiceConfigTypes } from '../../actions/editServiceConfig/actionTypes';
import { editServiceConfigFetching, editServiceConfigError, editServiceConfigSuccess } from '../../actions/editServiceConfig/action';
import { getListServiceConfig} from '../../actions/getListServiceConfig/action';
import { handleUpdateServiceConfig } from '../../../apis/service/ServiceConfig';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* editServiceConfigSaga(action: any): Generator<any> {
    const { payload } = action;
    yield put(editServiceConfigFetching(true))
    try {
        const dataEditLanguageText: any = yield handleUpdateServiceConfig(payload)
        if (dataEditLanguageText && dataEditLanguageText?.status === 200) {
            yield put(getListServiceConfig())
            yield put(editServiceConfigSuccess(dataEditLanguageText))
            toastSuccess('bo_service_config_updated');
        } else {
            yield put(editServiceConfigError(dataEditLanguageText))
            toastError('bo_you_can_t_update_service_config');
        }
    } catch (error) {
        yield put(editServiceConfigError(error))
        toastError('bo_you_can_t_update_service_config');
    }
}

export function* watchEditServiceConfig() {
    yield takeEvery(EditServiceConfigTypes.EDIT_SERVICE_CONFIG, editServiceConfigSaga)
}