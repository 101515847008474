export const routerConstants = Object.freeze({
  ////Dashboard////
  HolaDashboard: '/dashboard/hola-overview',
  BlockchainDashboard: '/dashboard/crypto-overview',

  ////User management////
  //Agent
  UserScore: '/user-management/agent/rank-score',
  Applications: '/user-management/agent/application',
  AgentDetail: '/user-management/agent/rank-score/:id',

  //User
  ListUser: '/user-management/user/list',
  UserDetail: '/user-management/user/list/:id',
  ListUserDeactivated: '/user-management/user/black-list',
  P2PUserTransaction: '/user-management/user/list/transaction',
  P2PUserOffer: '/user-management/user/list/offer',
  KYCDetail: '/user-management/user/list/kyc',

  //KYC
  KYCList: '/security/kyc/list',
  KYCBlacklist: '/security/kyc/black-list',
  KYCApplication: '/security/kyc/application',
  KYCListDetail: '/security/kyc/list/detail',
  KYCBlacklistDetail: '/security/kyc/black-list/detail',
  KYCApplicationDetail: '/security/kyc/application/detail',

  ////P2P management////
  //Transaction
  P2PTransactionBuy: '/p2p-management/transaction/buy',
  P2PTransactionSell: '/p2p-management/transaction/sell',

  //Offer
  P2POfferBuy: '/p2p-management/offer/buy',
  P2POfferSell: '/p2p-management/offer/sell',
  P2POfferBuyDetail: '/p2p-management/offer/buy/detail',
  P2POfferSellDetail: '/p2p-management/offer/sell/detail',

  //Invoice
  Invoice: '/p2p-management/invoice',
  InvoiceDetail: '/p2p-management/invoice/detail',

  //DirectTransfer
  DirectTransfer: '/p2p-management/direct-transfer',

  ////Exchange management////
  //Crypto to crypto
  CryptoToCrypto: '/exchange-management/crypto-to-crypto',
  CryptoToCryptoDetails: '/exchange-management/crypto-to-crypto/details',

  //Hola to crypto
  HolaToCrypto: '/exchange-management/hola-to-crypto',
  HolaToCryptoDetails: '/exchange-management/hola-to-crypto/details',

  //Hola to hola
  HolaToHola: '/exchange-management/hola-to-hola',
  HolaToHolaDetails: '/exchange-management/hola-to-hola/detail',

  ////Crypto wallet management////
  //Overview
  CryptoWalletOverview: '/crypto-wallet-management/main/overview',

  //System wallet
  CryptoSystemWallet: '/crypto-wallet-management/main/system-wallet',
  CryptoSystemWalletHot: '/crypto-wallet-management/main/system-wallet/hot',
  CryptoSystemWalletCold: '/crypto-wallet-management/main/system-wallet/cold',
  CryptoSystemWalletGas: '/crypto-wallet-management/main/system-wallet/gas',
  TopupHistory: '/crypto-wallet-management/main/system-wallet/top-up-history',

  //User wallet
  UserWallet: '/crypto-wallet-management/main/user-wallet',
  UserWalletDetails: '/crypto-wallet-management/main/user-wallet-details',

  //Transaction
  Deposit: '/crypto-wallet-management/transaction/deposit',
  Withdraw: '/crypto-wallet-management/transaction/withdraw',
  Transfer: '/crypto-wallet-management/transaction/transfer',

  ////Setting////
  //App Setting
  Currences: '/setting/app-setting/currency',
  ListLanguageText: '/setting/app-setting/language-text',
  ConfigService: '/setting/app-setting/config',
  Announcements: '/setting/app-setting/announcement',
  Categories: '/setting/app-setting/announcement/categories',
  ListNotification: '/setting/app-setting/featured-notification',
  Commission: '/setting/app-setting/commission',
  TransactionLimit: '/setting/app-setting/transaction-limit',
  UserFunction: '/setting/app-setting/user-function',
  UserFunctionDetail: '/setting/app-setting/user-function/detail',

  //Backoffice Setting
  UserScoreSetting: '/setting/backoffice-setting/agent-scoring',
  RankPeriodSettingPage: '/setting/backoffice-setting/agent-ranks',

  //Priority
  Priorities: '/setting/priority',

  //Admin List
  Admines: '/admin-management/admin-list',

  //Admin Wallet
  Wallets: '/admin-management/wallet/admin-wallet',
  SupperAdminWallets: '/admin-management/wallet/super-admin-wallet',
  //Admin Level
  AdminRoleSetting: '/admin-management/admin-level',
  AdminRoleSettingDetail: '/admin-management/admin-level/:id',
});

export const agentRouterConstants = Object.freeze({
  //Dashboard
  AgentDashboard: '/agent/dashboard',

  //Offer management
  ExchangeMyOffer: '/offer-management/exchange/my-offer',
  ExchangeAllOffer: '/offer-management/exchange/all-offer',
  P2PBuyOffer: '/offer-management/p2p/buy-offer',
  P2PSellOffer: '/offer-management/p2p/sell-offer',
  P2PBuyOfferDetail: '/offer-management/p2p/buy-offer/detail',
  P2PSellOfferDetail: '/offer-management/p2p/sell-offer/detail',

  //Transaction management
  P2PBuyTransaction: '/transaction-management/p2p/buy',
  P2PSellTransaction: '/transaction-management/p2p/sell',
  // ExchangeTransaction: "/transaction-management/exchange",
  DirectTransferTransaction: '/transaction-management/direct-transfer',
  InvoiceTransaction: '/transaction-management/invoice',
  InvoiceTransactionCreate: '/transaction-management/invoice/create',
  InvoiceTransactionDetail: '/transaction-management/invoice/detail',

  //My wallet
  HolaMoneyWallet: '/my-wallet/hola-money',
  CryptoWallet: '/my-wallet/crypto',
  TokenDetail: '/my-wallet/crypto/token',

  //Setting
  LinkedAccount: '/setting/linked-account',
  AgentKYC: '/setting/kyc',
  TwoFA: '/setting/two-factor-authentication',
});

export const routerConst = {
  Login: '/login',
};
