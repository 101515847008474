import { EditServiceConfigTypes } from './actionTypes';
import {
    IEditServiceConfigSuccess,
    IEditServiceConfigError,
    IEditServiceConfigFetching,
    IEditServiceConfig,
} from "./interfaces";


export const editServiceConfig = (payload:Object): IEditServiceConfig => ({
    type: EditServiceConfigTypes.EDIT_SERVICE_CONFIG,
    payload
})

export const editServiceConfigFetching = (isFetching: boolean): IEditServiceConfigFetching => ({
    type: EditServiceConfigTypes.EDIT_SERVICE_CONFIG_FETCHING,
    isFetching
})

export const editServiceConfigSuccess = (data: any): IEditServiceConfigSuccess => ({
    type: EditServiceConfigTypes.EDIT_SERVICE_CONFIG_SUCCESS,
    data
})

export const editServiceConfigError = (errorMsg: any): IEditServiceConfigError => ({
    type: EditServiceConfigTypes.EDIT_SERVICE_CONFIG_FAILD,
    error: errorMsg
})