import { put, takeEvery, debounce } from 'redux-saga/effects';
import { GetListServiceConfigTypes } from '../../actions/getListServiceConfig/actionTypes';
import { getListServiceConfigFetching, getListServiceConfigSuccess, getListServiceConfigError } from '../../actions/getListServiceConfig/action';
import { handleGetAllServiceConfig } from '../../../apis/service/ServiceConfig';

function* getAllServiceConfigSaga(): Generator<any> {
    console.log("run saga")
    yield put(getListServiceConfigFetching(true))
    try {
        const dataListLanguageText: any = yield handleGetAllServiceConfig()
        if (dataListLanguageText && dataListLanguageText?.status === 200) {
            yield put(getListServiceConfigSuccess(dataListLanguageText))
        } else {
            yield put(getListServiceConfigError('error'))
        }
    } catch (error) {
        yield put(getListServiceConfigError(error))
    }
}

export function* watchAllServiceConfig() {
    yield takeEvery(GetListServiceConfigTypes.GET_LIST_SERVICE_CONFIG, getAllServiceConfigSaga)
}